$(document).ready(function() {
    var plan = $('.js-plan');
    var submitButton = $('.js-plan-btn');

    plan.on('click', function(e) {
        submitButton.removeAttr('disabled');
    });

    submitButton.on('click', function(e) {
        if ($(this).attr('disabled')) {
            e.preventDefault();
        }        
    });
});