$(document).ready(function() {
  // your code
  var window = $(window);

  // counter function
  function counter() {
    var counterMore = $('.js-counter-more');
    var counterLess = $('.js-counter-less');
    var usersList = $('.js-users-list');

    counterMore.click(function() {
      if (usersList.length) {
        var counterValue = $('.js-counter-value');
        var value = parseInt(counterValue.text());
        var userCard = '<div class="user-card"> <button type="button" class="user-card-edit js-modal" data-target="#user-edit"> <span class="icon-pencil"></span> </button> <div class="user-card-thumb"></div><div class="user-card-content"> <div class="user-card-name">-</div><div class="user-card-age">- years</div><hr> <div class="user-card-other"> 0 food habits <span class="user-card-other-icon icon-check"></span> </div><div class="user-card-other"> 0 allergy <span class="user-card-other-icon icon-cross"></span> </div></div></div>';
        counterValue.text(value + 1);
        usersList.append(userCard);
      } else {
        var counterValue = $(this).parent().find('.js-counter-value');
        var value = parseInt(counterValue.text());
        if (counterValue.hasClass('has-people-subtext')) {
          counterValue.html((value + 1) + "<small>people</small>");
          return;
        }
        counterValue.text(value + 1);
      }
    });

    counterLess.click(function() {
      if (usersList.length >= 1) {
        var counterValue = $('.js-counter-value');
        var value = parseInt(counterValue.text());
        if (value === 1 || usersList.children().length === 1) return;
        counterValue.text(value - 1);
        usersList.children().last().remove();
      } else {
        var counterValue = $(this).parent().find('.js-counter-value');
        var value = parseInt(counterValue.text());
        if (value === 1) return;
        if (counterValue.hasClass('has-people-subtext')) {
          counterValue.html((value - 1) + "<small>people</small>");
          return;
        }
        counterValue.text(value - 1);
      }
    });
  }

  // call counter function
  counter();


  // edit user function (remove readonly attribute in input fields)
  function editUser() {
    var userSettingsButton = $('.js-user-edit');

    userSettingsButton.click(function() {
      var userSettingsInput = $(this).parent().find(".js-user-inpt")[0];
      console.log('userSettingsInput', userSettingsInput)
      // remove readonly attribute
      userSettingsInput.removeAttribute('readonly');
      userSettingsInput.focus();
    });
  }

  // call edit user function
  editUser();


  // create a function to resize input field (js-auto-input-size) based on the value
  function autoInputSize() {
    var input = $('.js-auto-input-size');
    // var placeholder = input.attr('placeholder');
    // var placeholderLength = placeholder.length;
    var inputVal = input.val();
    var inputWidth = inputVal.length * 10;
    // input.css('min-width', placeholderLength * 10);
    input.css({
      // 'min-width': '24px',
      'width': inputWidth
    });
  }

  // when the input field is changed, call the autoInputSize function
  $('.js-auto-input-size').on('change keyup paste', function() {
    autoInputSize();
  });

})