$(document).ready(function() {
// create tabs function. On "js-tabs" click, get data-tab value and show div
    $('.js-tabs').on('click', function(){
        var tab = $(this).data('tab');
        console.log('tab: ' + tab)
        $('.js-tabs').removeClass('is-active');
        $('.js-tabs-content').removeClass('is-active');
        $(this).addClass('is-active');
        $('#'+tab).addClass('is-active');
        console.log('#'+tab+' is active', $('#'+tab))
    });
});