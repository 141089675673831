$(document).ready(function() {
    // on load and if "tuto-end" isn't exis't in local storage, add "tuto-active" class to body and display the first step
    if (sessionStorage.getItem('tuto-end') === null) {
        $('body').addClass('tuto-active tuto-step-1');
        $('.step-1').addClass('is-active');
    }

    // if "tuto-end" is exis't in local storage, remove "tuto-active" class from body
    if (sessionStorage.getItem('tuto-end') !== null) {
        $('body').removeClass('tuto-active');
    }

    // on click on "next button (js-tuto-next) remove "is-active" class from current step and add it to the next step (step-1, step-2, step-3)
    $('.js-tuto-next').on('click', function() {
        var currentStep = $(this).closest('.tuto-step');
        var currentStepNumber = Number(currentStep.attr('class').split(' ')[1].split('-')[1]);
        console.log('currentStepNumber ', currentStepNumber)
        $('body').removeClass('tuto-step-' + (currentStepNumber)).addClass('tuto-step-' + (currentStepNumber + 1));
        var nextStep = $(this).closest('.tuto-step').next('.tuto-step');
        currentStep.removeClass('is-active');
        nextStep.addClass('is-active');
    });

    // on click on "end button (js-tuto-end) remove "tuto-active" class from body and add "tuto-end" in local storage
    $('.js-tuto-end').on('click', function() {
        $('body').removeClass('tuto-active');
        sessionStorage.setItem('tuto-end', 'true');
    });

});