$(document).ready(function() {

    var trigger = $(".js-modal");
    var closeButton = $(".js-modal-close");

    function toggleModal() {
        var modal = $(this).data("target");
        $(modal).toggleClass("is-open");
        $('body').toggleClass("modal-open");
    }

    function closeModal() {
        $('.modal').removeClass("is-open");
        $('body').removeClass("modal-open");
    }

    trigger.click(toggleModal);
    closeButton.click(closeModal);
});